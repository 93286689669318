import styled from "styled-components";
import media from "styled-media-query";

const SectionContent = styled.section`
  max-width: 1000px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  ${media.lessThan("medium")`
    width: 90%;
  `}
`;

export default SectionContent;
