import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import SectionWrapper from "./shared/section-wrapper";
import SectionContent from "./shared/section-content";
import media from "styled-media-query";

const FooterWrapper = styled(SectionWrapper)`
  background-color: black;
  height: 15vh;
`;

const FooterContent = styled(SectionContent)`
  margin-top: 2.5em;
  color: #7f8aa7;
  letter-spacing: 2.8px;
  text-transform: uppercase;

  a {
    color: #8f9ab7;
  }

  display: grid;
  grid-template-areas: "logo copyright designer";
  grid-template-columns: 20% 1fr 20%;
  align-items: flex-start;

  ${media.lessThan("medium")`
    grid-template-areas: 
      "logo designer" 
      "copyright copyright";
    grid-template-columns: 0.9fr 1.1fr;
    grid-template-rows: 1fr 1fr;
  `}
`;

const FooterLogo = styled.div`
  grid-area: logo;
  margin-top: 3px;
`;

const FooterCopyright = styled.div`
  grid-area: copyright;
  text-align: center;
`;

const FooterDesigner = styled.div`
  grid-area: designer;
  text-align: right;
`;

const Footer = () => {
  const data = useStaticQuery(FooterQuery);

  return (
    <FooterWrapper>
      <FooterContent>
        <FooterLogo>
          <Img
            fluid={data.desktopImg.childImageSharp.fluid}
            alt={"Improx Games logo"}
          />
        </FooterLogo>
        <FooterCopyright>
          <p>{`© ${
            data.site.siteMetadata.company
          } ${new Date().getFullYear()}`}</p>
        </FooterCopyright>
        <FooterDesigner>
          <p>
            Web design by{" "}
            <a href="https://www.patsui.com" alt="Patsui portfolio">
              patsui.com
            </a>
          </p>
        </FooterDesigner>
      </FooterContent>
    </FooterWrapper>
  );
};

export default Footer;

const FooterQuery = graphql`
  {
    site {
      siteMetadata {
        company
      }
    }
    desktopImg: file(relativePath: { eq: "improx-logo-hor.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
