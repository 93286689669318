import React from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider } from "styled-components";
import { IconContext } from "react-icons";
import Footer from "../footer";

const theme = {
  background: "#02111a",
  main: "#ffffff",
  inactive: "#a2b0bc",
  highlight: "#0050f4",
  headerHeight: "8vh",
};

const ContentWrapper = styled.main`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.background};
`;

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <IconContext.Provider value={{ style: { verticalAlign: "top" } }}>
        <ContentWrapper>
          {children}
          <Footer />
        </ContentWrapper>
      </IconContext.Provider>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
